import * as React from "react"
import { Seo } from "../../components/seo"
import Layout from '../../components/layout'
import Sidebar from '../../components/sidebar'
import { StaticImage } from "gatsby-plugin-image"

const MissionPage = () => {
  return (
	<Layout>
		<section id="hero">
			<StaticImage src="../../images/hero-images/hero-image-1.jpg" alt="Hero Background" class="heroImage" />
			<div className="heroOverlay"></div>
			<div className="container">
				<h1>Mission</h1>
			</div>
		</section>
		<section id="pageContent">
			<div className="container withSidebar">
				<main>
					<h2>Our Mission</h2>
					<p>To bring people together to build homes, communities, and hope.</p>
					<h2>Our Vision</h2>
					<p>A world where everyone has a decent place to live.</p>
					<h2>Our Welcome</h2>
					<p>
						To include all supporters from all backgrounds, who believe in our mission and vision for
						a world that helps to shelter those in need.
					</p>
					<h2>Non-Proselytizing Policy</h2>
					<p>
						Habitat for Humanity Northwoods Wisconsin will not condition the receipt of assistance or participation 
						in habitat programs on any requirement that people listen and respond to a message intended to induce 
						people to join a religious movement, political party, or other cause or organization.  Habitat for Humanity 
						Northwoods Wisconsin's motivation is to unite people around the concept of “putting God’s love into action” 
						along with others from all faiths or with no faith convictions who are interested in helping those in need 
						of improved shelter.
					</p>
				</main>
				<Sidebar />
			</div>
		</section>
	</Layout>
  )
}
export default MissionPage

export const Head = () => (
	<Seo
        title="Mission | Habitat For Humanity Northwoods Wisconsin"
    />
)
